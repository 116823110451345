import React from 'react'
import { Loader } from '../../../utils/loaderButton'
import { useEffect } from 'react'
import { useState } from 'react'
import { ConfirmListDecline, ConfirmListShareOwner, GetConfirmedList, PenddingSendLease, RequestRentalApp } from '../../../lib/Api/RantelServiceApi'
import { Truncate } from '../../../utils/TrucateString'
import moment from 'moment'
import Home from '../../../assests/svg/home.svg'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import PdfPreview from '../../RenatalPanelComp/RentalApp/PdfPreview'
import { useTranslation } from 'react-i18next'
import gp4 from '../../../assests/svg/Group 4188.svg'
import RentalsDetail from '../../../pages/Property/PropertyDetail'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import PasswordVerify from '../RentalRegister/PasswordVerify'
import { setConfirmData, setIsLeaseCreate, } from '../../../Redux/UserAuthSlice/UserAuthSlice'
import ViewRentalApp from './ViewRentalApp'
import { MdMessage } from 'react-icons/md'
import { IoClose } from 'react-icons/io5'
import { AddRentalAppUser } from '../../../Redux/UserAuthSlice/RentalSlice'
import { MylistDetail } from '../../GenericComp/MylistDetail'
import PropertyOffCampus from '../../RenatalPanelComp/FilterRommate/PropertyDetaiOffCamp'
import PropertyOnCampus from '../../RenatalPanelComp/FilterRommate/PropertyDetailOnCamp'
import OwnerDetail from './OwnerDetail'
import VerifyRentalApp from '../../RenatalPanelComp/RentalApp/VerifyRentalApp'

const Confirmed = () => {

  const [confirmList, setConfirmList] = useState([])
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState("")
  const [checkLoading, setCheckLoading] = useState(false)
  const [property, setProperty] = useState({})
  const [viewLease, setViewLease] = useState(false)
  const [leasePdf, setLeasePdf] = useState("")
  const [remover, setRemover] = useState(false)
  const [isVerify, setIsVerify] = useState(false)


  const location = useSelector((state) => state?.userAuth?.location)
  const lng = useSelector((state) => state?.userAuth?.language)
  const { ConfirmData, isLeasedCreate } = useSelector((state) => state?.userAuth)
  let check = Object.keys(location)?.length > 0

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const locationData = useLocation().pathname

  let fetchData = async () => {

    let { resConfirm } = await GetConfirmedList(location)
    if (resConfirm) {
      setConfirmList(resConfirm)
      return true
    }
  }

  // const handleSendRent = async (list) => {
  //   setType("signlease")
  //   // setShowId(list)
  //   // setViewLease(true)
  //   setProperty(list)
  // }

  // const handleUpdate = async () => {
  //   setCheckLoading(true)
  //   await fetchData()
  //   setCheckLoading(false)
  // }
  const handleRequestRenatal = async (list) => {
    // setShowId(list?.id)
    setType("leasereques")
    setProperty(list)
    let payload = {
      "property_id": Number(list?.property_id),
      "list_id": list?.id
    }
    setCheckLoading(true)
    let { res } = await RequestRentalApp(payload)
    if (res) {
      await fetchData()
      setCheckLoading(false)
    }
  }

  const sendLease = async (list) => {

    let payload = {
      "list_id": list?.id
    }
    setType("signlease")
    setProperty(list)
    setCheckLoading(true)
    let res = await PenddingSendLease(payload)
    if (res) {
      await fetchData()
      dispatch(setConfirmData({}))
      setCheckLoading(false)
    }
  }

  
  const handleVerify = () => {
    setIsVerify(false)
    setViewLease(true)
  }


  const handleMessage = (data) => {
    dispatch(AddRentalAppUser(data))
    navigate(`/message/${data?.id}`)
  }

  const handleRemove = async (item) => {
    setType("remove")
    setProperty(item)
    let payload = {
      "list_id": Number(item?.id),
    }

    setRemover(true)
    let { res } = await ConfirmListDecline(payload)
    if (res) {
      let res = fetchData()
      if (res === true) {
        setRemover(false)
      }
      setRemover(false)

    }
  }

  // const handleClose = async () => {
  //   setViewLease(false)

  // }



  const handleShare = async (item) => {
    // setType("shareOwner")
    // setProperty(item)
    // setViewLease(true)
    try {
      setType("share")
      let payload = {
        "list_id": item?.id,
      }
      setCheckLoading(true)
      let { res } = await ConfirmListShareOwner(payload)
      if (res) {
        await fetchData()
        setCheckLoading(false)
      }
    }
    catch (err) { }

  }

  // const handleEndLeased = async (item) => {
  //   setProperty(item)
  //   let payload = {
  //     "list_id": item?.property_id,
  //   }
  //   setCheckLoading(true)
  //   let { res } = await ConfirmedEndLease(payload)
  //   if (res) {
  //     await fetchData()
  //     setCheckLoading(false)
  //   }
  // }


  const handleDetail = (list) => {
    setProperty(list)
    setViewLease(true)
  }


  const handlePopup = (item, type) => {
    setType(type)
    setViewLease(true)
    if (type === "lease") {
      setLeasePdf(item?.lease_sent?.pdf)
    }
    else {
      setIsVerify(true)
      setLeasePdf(item)

    }
  }

  const handleClosePopup = () => {
    setIsVerify(false)
    setViewLease(false)
    setType("")
  }


  const verifyUser = () => {
    sessionStorage.setItem('hasWatchLeaseApp', 'true');
    setViewLease(false)
    // dispatch(setLeased(true))
    dispatch(setIsLeaseCreate({ page: "Confirmed", data: true }))
    dispatch(setConfirmData(property))
    navigate(`/createBuilder/${property?.property?.id}`)
  }



  const checkVerify = (list) => {
    setProperty(list)
    const hasDeniedPermission = sessionStorage.getItem('hasWatchLeaseApp');
    if (!hasDeniedPermission) {
      setViewLease(true)
      setType("verify")
    }
    else {
      // dispatch(setLeased(true))
      dispatch(setIsLeaseCreate({ page: "Confirmed", data: true }))
      dispatch(setConfirmData(list))
      navigate(`/createBuilder/${list?.property?.id}`)
    }
  }

  useEffect(() => {
    let fetch = async () => {
      setLoading(true)
      // if (check) {
      let res = await fetchData()
      if (res) {
        setLoading(false)
      }
      // } else {
      //   setConfirmList([])
      // }
    }
    fetch()
  }, [lng, location])


  useEffect(() => {
    if (locationData === `/mylist` && isLeasedCreate === true) {
      sendLease(ConfirmData)
      dispatch(setIsLeaseCreate({ page: "", data: false }))
    }
  }, [location])

  const { t } = useTranslation()

  if (isVerify) {
    return (<DynamicPopup modalOpen={isVerify} data={<VerifyRentalApp handleClose={handleVerify} />}
      close={() => handleClosePopup()}
    />)
  } else {
    return (
      <>
        {/* <Link to="/property"> */}
        {viewLease && <DynamicPopup modalOpen={viewLease} data={type === "lease" ? <PdfPreview pdf={leasePdf} t={t} /> :
          type === "rentalapp" ? <ViewRentalApp list={leasePdf} /> :
            // type === "shareOwner" ? <OwnerDetail list={property} close={handleClose} /> :
            type === "verify" ? <PasswordVerify close={() => verifyUser()} /> : property?.property === null ? property?.roommate?.property_details !== null ? <PropertyOffCampus detail={property?.roommate} close={() => setViewLease(false)} /> : <PropertyOnCampus detail={property?.roommate} close={() => setViewLease(false)} /> : <RentalsDetail detail={property?.property} close={() => setViewLease(false)} />}
          close={(val) => setViewLease(val)} />
        }

        <div className=' blog_list  mt-4'>
          {
            confirmList?.length > 0 ?
              <div className='grid lg:grid-cols-4 gap-5'>
                {

                  confirmList?.map((list, i) => (
                    <div className='rounded-md bg-white shadow-md pb-4 mb-8 w-full relative' key={i}>
                      <div className='relative'>
                        <img src={list?.property !== null ? list?.property?.image : list?.roommate?.images?.length > 0 ? list?.roommate?.images[0]?.image : list?.roommate?.user?.user_image} className="w-full h-[200px] object-cover" alt="faverit_img" />
                        <div className='absolute top-0 w-full rounded-md bg-black h-full bg-opacity-[0.5]' />
                        <div className='absolute top-[7%]  right-[5%]'>
                          <div className=' cursor-pointer rounded-full p-[3px] w-8 h-8 flex justify-center items-center text-white bg-[#F63030] ' onClick={() => handleRemove(list)}>
                            <div className='text-[20px] ' >
                            </div>{property?.id === list?.id && remover === true && type === "remove" ? <div className='flex items-start' ><Loader type="single" /> </div> : <IoClose />}
                          </div>
                          <div onClick={() => handleMessage(list?.user)} className='mt-1 cursor-pointer p-[3px] text-white bg-blue-500 w-8 h-8 flex justify-center items-center rounded-full' >
                            <div className='text-[18px] ' >
                            </div> <MdMessage />
                          </div>
                        </div>
                        <div className='absolute p-[2px] top-[7%] text-white bg-gray-200 rounded-full left-[5%]'>
                          <div className='text-[20px] cursor-pointer' onClick={() => handleDetail(list)}>
                            <img src={gp4} alt="detail" className='w-6 h-6' />
                          </div>
                        </div>
                        <MylistDetail list={list} />
                      </div>
                      {check &&
                        <div className='px-3 pt-2'>
                          <p className='text-[12px]'>{list?.property?.distance}</p>
                        </div>
                      }
                      <div className='flex justify-between items-center p-3 h-[50px]'>
                        <div className='flex items-center w-[80%] '>
                          <img src={list.user?.user_image} className="w-[40px] h-[35px] border object-cover rounded-full" alt="user" />
                          <h2 className='font-bold mx-2 text-[14px]  max-w-[120px]'>{Truncate(list?.user?.first_name, 10)}</h2>
                        </div>
                        <div className='w-[50%]'>
                          <h2 className='text-[11px] font-semibold text-right'>{moment(list?.booking_details?.date_of_appointment).format('ll')}</h2>
                          <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.time_of_appointment} </h2>
                        </div>

                      </div>

                      <div className='w-full '>
                        {list?.roommate === null ?
                          <div className='flex justify-between items-center gap-2 flex-col mx-2'>
                            {/* <button onClick={() => handlePopup(list)} className='px-2 py-1 rounded-md bg-[#F79F15] text-white'>{t('view_lease')}</button> */}
                            {
                              list?.lease_sent !== null ?
                                <button onClick={() => handlePopup(list, "lease")} className='px-2 py-1 rounded-md bg-[#F79F15] text-white w-full'>{t('view_lease')}</button>
                                :
                                list?.property?.lease_built === null ?
                                  <>
                                    <button onClick={() => checkVerify(list)} className='px-1 w-full py-[3px] rounded-md bg-[#F79F15] text-white'>
                                      {t('snd_lease')}</button>
                                  </>
                                  :
                                  <button onClick={() => sendLease(list)} className={`px-[6px] py-1   rounded-md bg-[#F79F15] w-full text-white ${property?.id === list?.id && 'bottom-0 px-1 py-1 '} `}>
                                    {property?.id === list?.id && type === "signlease" && checkLoading ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('snd_lease')}
                                  </button>
                            }
                            {list?.rental_app_sent !== null ?
                              <button onClick={() => handlePopup(list, "rentalapp")} className={`px-3 w-full py-1   rounded-md bg-blue-500 text-white ${property?.id === list?.id && 'bottom-0 px-1 py-1 '} `}>
                                {t('view_rent_app')}
                              </button>
                              :
                              <button onClick={() => handleRequestRenatal(list)} className={`px-1 py-1  w-full  rounded-md bg-blue-500 text-white ${property?.id === list?.id && 'bottom-0 px-2 py-1 '} `}>
                                {property?.id === list?.id && type === "leasereques" && checkLoading ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('snd_req_rnt_ap')}
                              </button>
                            }
                            {/* <button onClick={() => handleSendRent(list)} className={`px-2 py-1   rounded-md bg-red-500 text-white ${property?.id === list?.id && 'bottom-0 px-2 py-1 '} `}>
                          {property?.id === list?.id && checkLoading ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('end_lease')}
                        </button> */}
                          </div>
                          :
                          list?.contact_shared === null &&
                          <div className='flex justify-between items-center gap-2 flex-col mx-2'>
                            <button onClick={() => handleShare(list)}
                              className='px-2 py-1 disabled:cursor-not-allowed rounded-md bg-[#F79F15] text-white w-full'>
                              {property?.id === list?.id && type === "share" && checkLoading ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('share_owner_contact')}</button>
                          </div>
                        }
                      </div>
                    </div>

                  ))

                }


              </div>
              :
              loading ? <div className='flex justify-start items-start'><Loader type="loadData" /> </div> : <div className='text-center'>
                <div className='flex justify-center items-center w-full '>
                  <div className='flex justify-center items-center'>
                    <img src={Home} alt="home" className='w-[70%]' />
                  </div>
                </div>
                <div className='pt-5'>
                  <h2 className='text-xl font-bold '>{t('record_not_found')}</h2>
                </div>
              </div>

          }
        </div>
        {/* </Link> */}
      </>
    )
  }
}

export default Confirmed