import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    language: "en",
    locateDirect: "all",
    isNotLeasedBuild: false,
    LeasedBuild: false,
    ConfirmData: null,
    isLeasedCreate: { page: "", data: false },
    removeId: "",
    leasebuildData: {},
    chatUsers: [],
    hiddenChat: false,
    AllMessages: [],
    location: {},
    loginInfo: {
        token: null,
    },
    userInfo: {
        fname: null,
        lname: null,
        email: null,
        city: null,
        countryState: null,
        phone: null,
        zipcode: null,
        address: null,
        userid: null,
        userimg: null,
        userRole: null,
        CreatedAt: null,
        id_verified_at: null
    },


}
export const UserAuthSlice = createSlice({
    name: 'UserAuthSlice',
    initialState,
    reducers: {
        changeLang: (state, action) => {
            state.language = action.payload
        },

        signin: (state, action) => {
            state.loginInfo.token = action?.payload?.token
            state.userInfo.fname = action?.payload?.user?.first_name
            state.userInfo.lname = action?.payload?.user?.last_name
            state.userInfo.email = action?.payload?.user?.email
            state.userInfo.phone = action?.payload?.user?.phone_number
            state.userInfo.city = action?.payload?.user?.city
            state.userInfo.countryState = action?.payload?.user?.state
            state.userInfo.zipcode = action?.payload?.user?.zip
            state.userInfo.address = action?.payload?.user?.address
            state.userInfo.userimg = action?.payload?.user?.user_image
            state.userInfo.userid = action?.payload?.user?.id
            state.userInfo.userRole = action?.payload?.user?.role
            state.userInfo.CreatedAt = action?.payload?.user?.created_at
            state.userInfo.idVerified = action?.payload?.user?.id_verified_at

        },
        updateUser: (state, action) => {
            state.userInfo.fname = action?.payload?.user?.first_name
            state.userInfo.lname = action?.payload?.user?.last_name
            state.userInfo.email = action?.payload?.user?.email
            state.userInfo.phone = action?.payload?.user?.phone_number
            state.userInfo.city = action?.payload?.user?.city
            state.userInfo.countryState = action?.payload?.user?.state
            state.userInfo.zipcode = action?.payload?.user?.zip
            state.userInfo.address = action?.payload?.user?.address
            state.userInfo.userimg = action?.payload?.user?.user_image
            state.userInfo.userid = action?.payload?.user?.id
            state.userInfo.userRole = action?.payload?.user?.role
            state.userInfo.CreatedAt = action?.payload?.user?.created_at
            state.userInfo.idVerified = action?.payload?.user?.id_verified_at
        },
        setlocation: (state, action) => {
            state.location = action?.payload
        },

        setLocateNotification: (state, action) => {
            state.locateDirect = action?.payload
        },
        setUploadLease: (state, action) => {
            state.isNotLeasedBuild = action?.payload
        },
        setUploadLeaseData: (state, action) => {
            state.leasebuildData = action?.payload
        },
        setRemoveId: (state, action) => {
            state.removeId = action?.payload
        },
        setLeased: (state, action) => {
            state.LeasedBuild = action?.payload
        },
        setUsers: (state, action) => {
            state.chatUsers = action?.payload
        },
        setAllUser: (state, action) => {
            state.hiddenChat = action?.payload
        },
        setUsersList: (state, action) => {
            state.chatUsers = action?.payload
        },
        setUpdateUser: (state, action) => {
            let updatedArray = state.chatUsers?.map((user) => {
                if (user?.receiverID === Number(action.payload?.id)) {
                    return {
                        ...user,
                        unReadCount: action.payload?.count,
                    };
                }
                return user;
            });

            return {
                ...state,
                chatUsers: updatedArray,
            };
        },
        setUpdateMessage: (state, action) => {
            let updatedArray = state.chatUsers?.map((user) => {
                if (user?.senderID === Number(action.payload?.id)) {
                    return {
                        ...user,
                        lastMessage: action.payload?.message,
                        timestamp: action.payload?.time
                    };
                }
                return user;
            });

            return {
                ...state,
                chatUsers: updatedArray,
            };
        },

        setMessagesData: (state, action) => {
            state.AllMessages = action.payload
        },
        setIsLeaseCreate: (state, action) => {
            state.isLeasedCreate = action?.payload
        },
        setConfirmData: (state, action) => {
            state.ConfirmData = action?.payload
        },
        signout: () => {
            return initialState
        }
    },
})

// Action creators are generated for each case reducer function
export const { changeLang, signin, signout, updateUser, setlocation, setLocateNotification, setUploadLease, setUploadLeaseData, setRemoveId, setLeased, setIsLeaseCreate,
    setConfirmData, setUsers, setMessagesData, setUpdateMessage, setUsersList, setUpdateUser, setAllUser } = UserAuthSlice.actions

export default UserAuthSlice.reducer