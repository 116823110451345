import React, { useState, useEffect, useRef } from 'react'
import { IoLocation } from 'react-icons/io5'
import { BiCurrentLocation } from 'react-icons/bi'
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import LikeDislikePopup from '../../../utils/Popup/LikeDisLike';
import PostAppointment from '../../../utils/Popup/PostAppointment'
import propImg from '../../../assests/svg/home.svg'
import gp1 from '../../../assests/images/gp1.png'
import gp2 from '../../../assests/images/gp2.png'
import gp3 from '../../../assests/images/gp3.png'
import gp4 from '../../../assests/images/gp4.png'
import Arrow from '../../../assests/svg/arrow.svg'
import bath from '../../../assests/images/bath.png'
import bed from '../../../assests/images/bed.png'
import { FaFastBackward } from "react-icons/fa";
import { GetLeasedPeriod, GetPropertyAmenities, GetPropertyType, FilterRentProperties, GetPaginationRentProp } from '../../../lib/Api/RentalAppApi';
import Autocomplete from "react-google-autocomplete";
import { Loader } from '../../../utils/loaderButton';
import axios from 'axios';
import PlacePicker from './PlacePicker';
import DynamicPopup from '../../../utils/Popup/DynamicPopup';
import Deck from '../../../components/Property/Tinder';
import RentalsDetail from '../../Property/PropertyDetail';
// import ConstData from '../../../contant/Constant.json'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LocationAccess from '../../../components/GenericComp/LocationAccess';
import CreditConfirm from '../../../components/GenericComp/CreditConfirmation';
// import { API } from '../../../config/GoolePlace';
// import MobileProperty from './MobileProperty';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
const RentalProperty = () => {
    const [active, setActive] = useState(0)
    const [show, setShow] = useState(false)
    const [showPost, setShowPost] = useState(false)
    const [isCredit, setIsCredit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false)
    const [propertyType, setPropertyType] = useState([])
    const [leasedTye, setLeasedType] = useState([])
    const [aminities, setAminities] = useState([])
    const [listing, setListing] = useState([])
    const [lists, setLists] = useState([])
    const [originalLists, setOriginalLists] = useState([])
    const [type, setType] = useState("")
    const [activePoperty, setActiveProperty] = useState([])
    const [activeLeased, setActiveLeased] = useState({})
    const [property, setProperty] = useState({})
    const [locate, setLocate] = useState("")
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [priceRange, setPriceRange] = useState(20000);
    const [radius, setRadius] = useState(30);
    const [place, setPlace] = useState(null);
    const [UsAddress, setUsAddress] = useState(false)
    const [defaultAddress, setDefaultAdress] = useState("")
    const [detail, setDetail] = useState("")
    const [swapSlider, setSwapSlider] = useState("")
    const [nextLink, setNextLink] = useState("")
    const [purchaseType, setPurchaseType] = useState("")
    const [addressCityState, setAddressCityState] = useState({})
    const [currentIndex, setCurrentIndex] = useState(0);
    const [afterSearch, setAfterSearch] = useState(false)
    const [beds, setBeds] = useState([]);
    const [baths, setBaths] = useState([]);
    const [isLocation, setIsLocation] = useState(false)
    const [scrolBottom, setScrolBottom] = useState(false)
    const [googleLoaded, setGoogleLoaded] = useState(false);

    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const language = useSelector((state) => state?.userAuth)
    const { rentAppSend } = useSelector((state) => state?.rental)
    // const location = useLocation().pathname

    const { t } = useTranslation()


    const containerRef = useRef(null);
    const topRef = useRef(null);

    //  Icon on above property 
    let Icons = [
        { img: gp1, type: "dislike" },
        { img: gp2, link: "/flash" },
        { img: "", type: "backForword" },
        { img: gp3, link: "/reazy" },
        { img: gp4, type: "like" },
    ]

    // handle Price and handle Redius value
    const handleRangeChange = (event) => {
        setPriceRange(event.target.value);
    };

    const handleRadius = (event) => {
        setRadius(event.target.value);
    };

    // add doller sign in values
    const formatCurrency = (value) => {
        return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    };
    const formattedValue = formatCurrency(priceRange);
    const formattedRadius = formatCurrency(radius);

    // handle checkboxs
    const HandleChecks = (id) => {

        if (lists?.includes(id)) {
            let l = lists.filter((f) => f !== id)
            setLists(l)

        }
        else {
            setLists((prev) => ([...prev, id]))
        }
    }
    // Filter property Function
    const FilterProperty = async () => {
        let updateArr = []
        for (let index = 0; index < activePoperty.length; index++) {
            const element = activePoperty[index];
            updateArr.push(element.id)
        }

        let updateLeased = activeLeased.id
        let data = {
            lists,
            priceRange,
            updateArr,
            updateLeased,
            lat: latitude,
            lng: longitude,
            beds: beds,
            baths: baths,
            state: addressCityState?.state,
            city: addressCityState?.city,
            street: addressCityState?.street,
            zipcode: addressCityState?.zipcode,
            radius: Number(radius),

        }

        // topRef.current.scrollIntoView({ behavior: 'smooth' });
        if (defaultAddress !== "") {
            setScrolBottom(true)
        }
        setSearchLoading(true)
        let { res, error } = await FilterRentProperties(data)
        if (res) {
            setSearchLoading(false)
            // setDemo(false)
            setScrolBottom(false)
            let ObjProperty = Object.assign({}, ...res)
            const reversedRoommates = ObjProperty?.listings.reverse();
            setListing(reversedRoommates);
            if (ObjProperty.listings?.length === 0) {
                setAfterSearch(true)
            }
            else {
                setAfterSearch(false)
            }
            setNextLink(ObjProperty?.next_page_url)
        }
        else if (error) {
            setSearchLoading(false)

        }
    }
    // handle Property type checkbox
    const handlePropertyType = (data, name) => {
        if (name === t('property_any')) {
            setActiveProperty(propertyType)
        } else {
            let checkId = activePoperty.find((f) => f.id === data?.id)
            if (checkId) {
                let UpdatedArry = activePoperty.filter((f) => f.id !== data?.id)
                setActiveProperty(UpdatedArry)
            }
            else {
                setActiveProperty([...activePoperty, data])
            }
        }
    }
    // check the user current location in US or out of 
    // const isLocationInUS = () => {
    //     if (!place) {
    //         return false;
    //     }
    //     for (const component of place.address_components) {
    //         if (
    //             component.types.includes('country') &&
    //             (component.short_name === 'US' || component.long_name === 'United States')
    //         ) {
    //             return true;
    //         }
    //     }

    //     return false;
    // };
    // handle check address in area of US

    const autocompleteOptions =
    {
        types: ['(regions)'],
        componentRestrictions: { country: 'us' },
    }
    const handleSelectBedBath = (index, name) => {
        if (name === "bath") {
            setBaths(prev =>
                prev.includes(index) ? prev.filter(b => b !== index) : [...prev, index]
            );
        } else if (name === "bed") {
            setBeds(prev =>
                prev.includes(index) ? prev.filter(b => b !== index) : [...prev, index]
            );
        }
    };


    // var autocompleteOptions = {
    //     types: ['(regions)'],
    //     componentRestrictions: { country: "us" }
    // };

    // get location detail 
    // const fetchPlaceDetails = async (latitude, longitude) => {
    //     try {
    //         const response = await axios.get(
    //             `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBD-xaHdgobMyufM5tW3whuusr8l5J57X0`
    //         );
    //         const results = response.data.results;
    //         if (results.length > 0) {
    //             let locationData = results[0]
    //             setDefaultAdress(locationData?.formatted_address);
    //             if (locationData?.formatted_address) {
    //                 let result = isLocationInUS()
    //                 if (result === false) {
    //                     setUsAddress(true)
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         //console.error('Error fetching place details:', error);
    //     }
    // };

    //handle Next and Previus Property base on like and dislike button
    const handleNextProperty = (type) => {
        if (type === "like") {
            setSwapSlider("like")
            swipeRight()
        }
        else {
            setSwapSlider("dislike")
            swipeLeft()
        }
    }

    // Function to handle left swipe
    const swipeLeft = async (id) => {
        if (id) {

            const updatedData = listing.filter((item, i) => item?.id !== id)
            if (listing?.length === 0 && nextLink !== "") {
                let { res } = await GetPaginationRentProp()
                let ObjProperty = Object.assign({}, ...res)
                if (ObjProperty.listings) {
                    setListing(ObjProperty?.listings)
                }
            }
            else {
                setListing(updatedData)
            }
        }
        else {
            const numberOfElementsToKeep = 1;
            const filteredData = listing.filter((item, index) => index >= listing.length - numberOfElementsToKeep);
            let obj = Object.assign({}, ...filteredData)
            const updatedData = listing.filter((item, i) => item?.id !== obj?.id)
            if (listing?.length === 0 && nextLink !== "") {
                let { res } = await GetPaginationRentProp()
                let ObjProperty = Object.assign({}, ...res)
                if (ObjProperty.listings) {
                    setListing(ObjProperty?.listings)
                }
            }
            else {
                let check = originalLists.find((f) => f.id === obj.id)
                if (!check) {
                    setOriginalLists((prev) => [...prev, ...filteredData])
                }
                setListing(updatedData)
            }
        }

    };

    // Function to handle right swipe
    const swipeRight = async () => {
        // if (currentIndex < listing.length - 1) {
        const numberOfElementsToKeep = 1;
        const filteredData = listing.filter((item, index) => index >= listing.length - numberOfElementsToKeep);
        let obj = Object.assign({}, ...filteredData)
        const updatedData = listing.filter((item, i) => item?.id !== obj?.id)
        if (listing?.length === 0 && nextLink !== "") {
            let { res } = await GetPaginationRentProp()
            let ObjProperty = Object.assign({}, ...res)
            if (ObjProperty.listings) {
                setListing(ObjProperty?.listings)
            }
        }
        else {
            setListing(updatedData)
            let check = originalLists.find((f) => f.id === obj.id)
            if (!check) {
                setOriginalLists((prev) => [...prev, ...filteredData])
            }
            setShow(true)
            setProperty(obj)
        }



        // }
    };

    // Revert property

    const RevertProperty = () => {
        const numberOfElementsToKeep = 1;
        const filteredData = originalLists.filter((item, index) => index >= originalLists.length - numberOfElementsToKeep);
        let obj = Object.assign({}, ...filteredData)
        setListing((prev) => [...prev, obj])
        let check = originalLists.filter((f) => f.id !== obj?.id)
        setOriginalLists(check)
    }

    const handleClose = () => {
        setSearchLoading(false)
        setListing([])
    }



    let fetchPropety = async () => {
        setLoading(true)
        let property = await GetPropertyType()
        let ObjProperty = Object.assign({}, ...property?.res)
        setPropertyType(ObjProperty?.property_type)
        let aminty = await GetPropertyAmenities()
        let Objaminty = Object.assign({}, ...aminty?.res)
        setAminities(Objaminty?.amenities)
        let peroid = await GetLeasedPeriod()
        let Objperoid = Object.assign({}, ...peroid?.res)
        if (Objperoid?.lease_periods.length > 0) {
            setActiveLeased(Objperoid?.lease_periods[0])
        }
        setLeasedType(Objperoid?.lease_periods)
        setLoading(false)
        if (defaultAddress === "") {
            FilterProperty()
        }
    }

    // get api data from server 
    useEffect(() => {
            fetchPropety()
    }, [])

 
    useEffect(() => {
        // Check if the cookie exists
        const hasDeniedPermission = sessionStorage.getItem('hasDeniedPermission');
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // Extract latitude and longitude from the position object
                    const { latitude, longitude } = position.coords;
                    // setLatitude(latitude);
                    // setLongitude(longitude);
                    // fetchPlaceDetails(latitude, longitude);
                },
                (err) => {
                    if (err.code === err.PERMISSION_DENIED) {
                        if (!hasDeniedPermission) {
                            setIsLocation(true)
                            sessionStorage.setItem('hasDeniedPermission', 'true');
                        }
                    }
                }
            );
        } else {
            //console.error('Geolocation is not supported in this browser.');
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setInnerWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (rentAppSend?.id) {
            setShow(true)
        }
    }, [rentAppSend])


    useEffect(() => {
        if (topRef.current && scrolBottom === true ) {
            topRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
        }
    }, [scrolBottom])

    return (
        <div className='overflow-hidden' ref={topRef} >
            {/* <div ></div> */}
            {detail && <DynamicPopup modalOpen={detail} data={<RentalsDetail swipeLeft={(val) => swipeLeft(val)} detail={detail} close={() => setDetail("")} />} close={() => setDetail("")} />}
            {isLocation && <DynamicPopup data={<LocationAccess close={() => setIsLocation(false)} />} modalOpen={isLocation} close={() => setIsLocation(false)} />}
            {isCredit && <DynamicPopup data={<CreditConfirm purchaseType={purchaseType} close={() => setIsCredit(false)} />} modalOpen={isCredit} close={() => setIsCredit(false)} />}
            {
                // UsAddress ?
                //     <DynamicPopup modalOpen={UsAddress} data={<PlacePicker setAddressCityState={setAddressCityState} setLatitude={setLatitude} setLongitude={setLongitude} setDefaultAdress={setDefaultAdress} setLocate={setLocate} close={setUsAddress} />} close={setUsAddress} />
                //     :
                <>
                    {show && <LikeDislikePopup setPurchaseType={setPurchaseType} setShowPost={setShowPost} permition={show} Toggle={setShow} type={type} property={property} setIsCredit={setIsCredit} />}
                    {showPost && <PostAppointment swipeLeft={(val) => swipeLeft(val)} permition={showPost} Toggle={setShowPost} type={type} property={property} />}

                    {
                        <Link to="/dashboard">
                            <div className=' xl:flex lg:flex md:flex hidden p-4 items-center xl:ml-5 lg:ml-5  cursor-pointer' >
                                <IoIosArrowBack className="text-[20px]" />
                                <h2 className='text-[16px] font-semibold'>Dashboard</h2>
                            </div>
                        </Link>
                    }
                    {/* Desktop */}
                    <div className="container mx-auto xl:block lg:block md:block hidden">
                        <div className="xl:grid lg:grid md:grid hidden  lg:grid-cols-4 gap-3 " >
                            <div className='border rounded shadow-md w-full h-full' >
                                <div className='border-b border-[#F79F15] flex '>
                                    <div onClick={() => setActive(0)} className={`flex justify-center mt-3 pb-1  cursor-pointer items-center w-full ${active === 0 && 'border-b-2 border-[#F79F15]'}`}>
                                        <h2 className={`${active === 0 && 'text-[#F79F15]'} text-center text-[18px] font-semibold`}>{t('servicer_rentals')}</h2>
                                    </div>
                                    {/* <div onClick={() => setActive(1)} className={`flex justify-center mt-3 pb-1 cursor-pointer items-center w-full ${active === 1 && 'border-b-2 border-[#F79F15]'}`}>
                                            <h2 className={`${active === 1 && 'text-[#F79F15]'} text-center text-[18px] font-semibold`}>{t('rommates')}</h2>
                                        </div> */}
                                </div>
                                <div className='m-4'>
                                    <h2 className='font-semibold text-[18px]'>{t('leased_p')}</h2>
                                    <div className='flex flex-wrap  items-center'>
                                        {
                                            loading ?

                                                <Loader type="loadData" />
                                                :
                                                leasedTye?.map((p, i) => (
                                                    <button key={i} onClick={() => setActiveLeased(p)} className={`border my-2  rounded-md xl:mx-0 lg:mx-2 mx-2 first:mx-0 last:mx-0 px-4  py-1.5 ${activeLeased.id === p.id ? 'bg-[#F79F15] text-white' : 'bg-white text-[#F79F15]'}`}>
                                                        {p?.title}</button>

                                                ))
                                        }
                                    </div>

                                    <h2 className='text-[20px] font-semibold mt-4'>{t('location')}</h2>
                                    <div className='flex items-center justify-between my-4'>
                                        <div className=' bg-[#F5F7F9] rounded-md w-full p-1 mr-2 flex  items-center'>
                                            <IoLocation className='text-[#A5A5A5]' />
                                            <Autocomplete
                                                apiKey={"AIzaSyCpBFsGdMzJ_qo1PPGUM6i0g__WTZuzTQk"}
                                                options={autocompleteOptions}
                                                defaultValue={defaultAddress}
                                                onPlaceSelected={async (place) => {
                                                    setLocate(place?.formatted_address)
                                                    setPlace(place)
                                                    setDefaultAdress(place?.formatted_address)
                                                    setLatitude(place.geometry?.location?.lat());
                                                    setLongitude(place.geometry?.location?.lng());
                                                    let city = "";
                                                    let state = "";
                                                    let zipcode = "";
                                                    let street = "";
                                                    let propertyId = "";

                                                    // Iterate through the address components
                                                    place?.address_components.forEach(component => {
                                                        const types = component.types;
                                                        // Check for the types relevant to city, state, and zipcode
                                                        if (types.includes("locality")) {
                                                            city = component.long_name;
                                                        } else if (types.includes("administrative_area_level_1")) {
                                                            state = component.short_name;
                                                        } else if (types.includes("postal_code")) {
                                                            zipcode = component.long_name;
                                                        }
                                                        else if (types.includes("route")) {
                                                            street = component.long_name;
                                                        }
                                                        else if (types.includes("street_number")) {
                                                            propertyId = component.long_name;
                                                        }
                                                    })

                                                    if (state !== "MA" && state !== "CT") {
                                                        toast.error(t('loca_erro'));
                                                        return;
                                                    }
                                                    let payload = {
                                                        city,
                                                        state,
                                                        zipcode,
                                                        street: `${propertyId} ${street}`
                                                    }
                                                    setAddressCityState(payload)

                                                }}

                                                className='w-full bg-[#F5F7F9]
                                            border-transparent  focus:outline-none placeholder:text-[#A5A5A5]'
                                                placeholder={t('select_location')}
                                            />
                                        </div>
                                        <div className=' p-2 rounded-md bg-[#F79F15]'>
                                            <BiCurrentLocation className='text-white' />
                                        </div>

                                    </div>
                                    <div>
                                        <h2 className='text-[20px] font-semibold my-4'>{t('property_type')}</h2>
                                        <div className='flex flex-wrap  items-center '>
                                            {

                                                loading ?

                                                    <Loader type="loadData" />
                                                    :
                                                    propertyType?.map((p, i) => (
                                                        <button key={i} onClick={() => handlePropertyType(p, p.name)} className={`border my-2  rounded-md px-4 mx-2 py-1.5 ${activePoperty.find((f) => f.id === p.id) ? 'bg-[#F79F15] text-white' : 'bg-white text-[#F79F15]'}`}>
                                                            {p.name}</button>

                                                    ))
                                            }

                                        </div>

                                    </div>
                                    <div>
                                        <h2 className='text-[20px] font-semibold my-4'>{t('mile_radius')}</h2>
                                        <div className='flex  items-center '>
                                            <span>0</span>
                                            <input
                                                type="range"
                                                min="0"
                                                className='progress' style={{ accentColor: "#FF8C00", width: '80%', color: 'red' }}
                                                max="100"
                                                step="5"
                                                value={radius}
                                                onChange={handleRadius}
                                            />

                                            <p> {formattedRadius !== 0 ? formattedRadius : "100"}</p>
                                        </div>

                                    </div>
                                    <div>

                                        <h2 className='text-[20px] font-semibold my-4'>{t('price_rng')}</h2>
                                        <div className='flex  items-center '>
                                            <span>$0</span>
                                            <input
                                                type="range"
                                                min="0"
                                                className='progress' style={{ accentColor: "#FF8C00", width: '80%', color: 'red' }}
                                                max="200000"
                                                step="1000"
                                                value={priceRange}
                                                onChange={handleRangeChange}
                                            />
                                            <p> {formattedValue}</p>
                                        </div>

                                    </div>
                                    <div className='flex items-center'>
                                        <div>


                                            <div>
                                                <div className='gap-2 flex items-center'>
                                                    <img src={bed} alt="" />
                                                    <h2 className='text-[18px] font-semibold my-4'>{t('beds')}</h2>
                                                </div>

                                                <div className='flex  items-center gap-1 '>
                                                    {Array(7).fill(7).map((_, i) => {
                                                        const isSelected = beds.includes(i + 1); // Check if bed is selected
                                                        return (
                                                            <div
                                                                key={i}
                                                                onClick={() => handleSelectBedBath(i + 1, "bed")} // Handle bed selection
                                                                className={`rounded-full border ${i + 1 === 7 ? 'px-[7px]' : 'px-[9px]'} cursor-pointer py-[2px] ${isSelected ? 'bg-[#F79F15]' : 'bg-gray-200'} text-white`}
                                                            >
                                                                <p>{i + 1}{i + 1 === 7 && "+"}</p>
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                            </div>
                                            <div>
                                                <div className='gap-2 flex items-center'>
                                                    <img src={bath} alt="" />
                                                    <h2 className='text-[18px] font-semibold my-4'>{t('baths')}</h2>
                                                </div>

                                                <div className='flex  items-center gap-1 '>
                                                    {Array(7).fill(7).map((_, i) => {
                                                        const isSelected = baths.includes(i + 1); // Check if bed is selected
                                                        return (
                                                            <div
                                                                key={i}
                                                                onClick={() => handleSelectBedBath(i + 1, "bath")} // Handle bed selection
                                                                className={`rounded-full border ${i + 1 === 7 ? 'px-[7px]' : 'px-[9px]'} cursor-pointer py-[2px] ${isSelected ? 'bg-[#F79F15]' : 'bg-gray-200'} text-white`}
                                                            >
                                                                <p>{i + 1}{i + 1 === 7 && "+"}</p>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h2 className='text-[20px] font-semibold my-4'>{t('amnity_lab')}</h2>
                                        <div className='flex flex-wrap items-center '>
                                            {
                                                loading ?

                                                    <Loader type="loadData" />
                                                    :
                                                    aminities?.map((c, i) => (
                                                        <div className='flex items-center mx-1' key={i}>
                                                            <input id={c.id} type="checkbox" onChange={() => HandleChecks(c?.id)} className='w-[20px] h-[20px]' />
                                                            <label htmlFor={c.id}>
                                                                <h2 className='mx-1 cursor-pointer text-[16px]'>{c.name}</h2>
                                                            </label>
                                                        </div>
                                                    ))

                                            }

                                        </div>
                                    </div>
                                    <button onClick={() => FilterProperty()} className='border w-full py-2 rounded-md text-white   bg-[#F79F15]'>{t('search_lab')}</button>

                                </div>
                            </div>
                            <div className="w-full col-span-3">
                                {searchLoading ?
                                    <div className='flex justify-center items-center '>
                                        <Loader type="loadData" />
                                        <div className='px-5'>
                                            <MdClose className='cursor-pointer' onClick={() => handleClose()} />
                                        </div>
                                    </div>
                                    :
                                    <div className={`max-w-full h-full w-[800px]  mx-auto `}>
                                        <div className={`flex justify-center items-center`}>
                                            {
                                                listing && listing?.length > 0 &&
                                                Icons.map((icon, i) => (
                                                    <>
                                                        {icon.link ? <Link to={icon?.link}>
                                                            <img className='cursor-pointer' key={i} src={icon.img} alt={icon.img} />
                                                        </Link> :
                                                            icon?.img === "" && originalLists?.length > 0 ?
                                                                <div className='border h-[55px] mb-[8px] w-[65px] rounded-md cursor-pointer flex justify-center items-center' onClick={() => RevertProperty()}>
                                                                    <FaFastBackward size={22} />
                                                                </div>

                                                                :
                                                                <img className='cursor-pointer' onClick={() => handleNextProperty(icon?.type)} key={i} src={icon.img} alt={icon.img} />
                                                        }
                                                    </>
                                                ))
                                            }
                                        </div>
                                        {
                                            listing && listing?.length > 0 ?
                                                <div className={`${listing?.length > 15 && 'mt-[3rem] m-auto'}  h-full `}>
                                                    <Deck currentIndex={currentIndex} swapSlider={swapSlider} toggle={setShow} listing={listing} setListing={(val) => setListing(val)} setDetail={setDetail} originalLists={originalLists} setOriginalLists={setOriginalLists} setProperty={setProperty} />
                                                </div>
                                                :
                                                loading ? `${t('wait_lab')} .....` :
                                                    <div className='flex flex-col'>
                                                        <div className="flex flex-row justify-center items-center">
                                                            <div className='xl:flex lg:flex hidden justify-center items-center rotate-90'>
                                                                <img src={Arrow} alt="home" />
                                                            </div>
                                                            <div className="flex flex-col justify-center items-center">
                                                                <img src={propImg} className='w-[40%]' alt="property_img" />
                                                                <h2 className='font-semibold text-[20px] pt-3 max-w-[250px] text-center'>{t('property_filter')}</h2>
                                                            </div>

                                                        </div>
                                                        <div className='w-[200px]  mx-auto'>
                                                            <button onClick={() => FilterProperty()} className='border mx-10 w-full mt-5 py-2 px-6 rounded-md text-white   bg-[#F79F15]'>{afterSearch ? t('re-search') : t('search_lab')}</button>
                                                        </div>
                                                    </div>

                                        }



                                    </div>

                                }
                            </div>
                        </div>
                    </div>


                    {/* Mobile */}
                    {/* <div className=''>
                        <MobileProperty 
                         
                        />
                    </div> */}
                </>
            }
        </div >
    )
}

export default RentalProperty