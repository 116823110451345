
import { Doughnut } from 'react-chartjs-2';
import { useEffect } from 'react';
import ChartJS from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';

// Register the datalabels plugin globally
ChartJS.register(ChartDataLabels);

const GaugeChart = ({ credit, setCredit }) => {
    const dataList = [
        { color: "red", name: 'Very Poor (300-579)' },
        { color: "orange", name: 'Poor (580-669)' },
        { color: "yellow", name: 'Fair (670-739)' },
        { color: "#3BA32D", name: 'Good (740-799)' },
        { color: "#3B5C0A", name: 'Excellent (800-850)' }
    ];

    useEffect(() => {
        ChartJS.register({
            id: 'gaugeNeedle',
            afterDatasetDraw: (chart, args, options) => {
                const ctx = chart.ctx;
                const meta = chart.getDatasetMeta(0);
                const element = meta.data[0];

                const centerX = element.x;
                const centerY = element.y;
                const baseRotation = (3 * Math.PI) / 2; // Start from 270 degrees

                const dataValues = [300, 580, 669, 740, 850];
                const angleRange = Math.PI;
                const totalSpan = dataValues[dataValues.length - 1] - dataValues[0];

                const creditInRange = (credit - dataValues[0]) / totalSpan;
                const needleRotation = baseRotation + (creditInRange * angleRange);

                ctx.save();
                ctx.translate(centerX, centerY);
                ctx.rotate(needleRotation);

                // Draw the needle
                ctx.beginPath();
                ctx.moveTo(0, 0);
                ctx.lineWidth = 5;
                ctx.lineTo(0, -100);
                ctx.lineCap = 'round';
                ctx.strokeStyle = 'green';
                ctx.stroke();

                // Draw center circle
                ctx.beginPath();
                ctx.arc(0, 0, 6, 0, 2 * Math.PI);
                ctx.fillStyle = '#fff';
                ctx.fill();
                ctx.strokeStyle = '#aaa';
                ctx.stroke();

                ctx.restore();
            },
        });
    }, [credit]);

    const data = {
        labels: ['300-579', '580-669', '670-739', '740-799', '800-850'],
        datasets: [
            {
                data: [1, 1, 1, 1, 1], // These values represent equal parts of the gauge
                backgroundColor: [
                    'red',
                    'orange',
                    'yellow',
                    '#3BA32D',
                    '#3B5C0A',
                ],
                borderColor: [
                    'red',
                    'orange',
                    'yellow',
                    '#3BA32D',
                    '#3B5C0A',
                ],
                borderWidth: 1,
                circumference: 180,
                rotation: 270,
                circumference: 180, // Half-doughnut
            },
        ],
    };

    const options = {
        responsive: true,
        rotation: -90,
        aspectRatio: 1,
        layout: {
            padding: {
                left: 40, // Adds space on the left side
                right: 50, // Adds space on the right side
            },
        },
        circumference: 180,
        rotation: 270,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const ranges = ['300-579', '580-669', '670-739', '740-799', '800-850'];
                        const scoreRange = ranges[tooltipItem.dataIndex];
                        return `${scoreRange}`;
                    },
                },
            },
            datalabels: {
                display: true,
                color: 'black',
                align: 'end', // Keep the text aligned to the outer side of the arc
                anchor: 'center', // Anchor the text outside the arc
                offset: 50, // Increase offset to push labels further outside
                formatter: (value, context) => {
                    const ranges = ['300-579', '580-669', '670-739', '740-799', '800-850'];
                    return ranges[context.dataIndex];
                },
                rotation: (context) => {
                    const index = context.dataIndex;
                    const totalSegments = context.chart.data.labels.length;
                    const anglePerSegment = 180 / totalSegments;  // Total degrees of the arc divided by number of segments
                    const baseRotation = -72;  // Start at the left side, rotating clockwise
                    const additionalRotation = anglePerSegment * index;  // Rotating each label based on its segment
                    return baseRotation + additionalRotation;  // Rotate labels accordingly to follow the arc
                },
                font: {
                    size: 20,
                    weight: 'bold',
                },
            },
        },
    };
    

    // const options = {
    //     responsive: true,
    //     rotation:-90,
    //     aspectRatio: 1,
    //     layout: {
    //         padding: {
    //             left: 30,
    //             right: 60,
    //         },
    //     },
    //     circumference: 180,
    //     rotation: 270,
    //     plugins: {
    //         legend: {
    //             display: false,
    //         },
    //         tooltip: {
    //             callbacks: {
    //                 label: function (tooltipItem) {
    //                     const ranges = ['300-579', '580-669', '670-739', '740-799', '800-850'];
    //                     const scoreRange = ranges[tooltipItem.dataIndex];
    //                     return `${scoreRange}`;
    //                 },
    //             },
    //         },
    //         datalabels: {
    //             display: true,
    //             color: 'black',
    //             align: 'center', // Adjust to 'end' for better alignment
    //             anchor: 'end', // Helps keep the labels at the correct arc points
    //             offset: 20, // Adjust this to position the labels further from the center
    //             formatter: (value, context) => {
    //                 const ranges = ['300-579', '580-669', '670-739', '740-799', '800-850'];
    //                 return ranges[context.dataIndex];
    //             },
    //             rotation: (context) => {
    //                 const index = context.dataIndex;
    //                 const totalSegments = context.chart.data.labels.length;
    //                 const anglePerSegment = 180 / totalSegments;  // Total degrees of the arc divided by number of segments
    //                 const baseRotation = -60;  // Start at the left side, rotating clockwise
    //                 const additionalRotation = anglePerSegment * index;  // Rotating each label based on its segment
    //                 return baseRotation + additionalRotation;  // Rotate labels accordingly to follow the arc
    //             },
    //             font: {
    //                 size: 20,
    //                 weight: 'bold',

    //             },
    //         }
    //     }
    // }

            const { t } = useTranslation();

            return(
        <div className = "  flex justify-center flex-col w-[500px]" >
                    { credit !== "" && (
                        <div className='cursor-pointer font-bold pb-2' onClick={() => setCredit('')}>
                            {t('Back')}
                        </div>
                    )}
            <h2 h2 className='text-center font-bold text-[25px]' > { t('credit_check') }</h2>
            <div className='  ml-5 flex justify-center items-center'>
                <Doughnut
                    data={{ ...data, credit: credit }}
                    options={options}
                />
            </div>
            <div className='mt-5'>
                {dataList?.map((list) => (
                    <div className='flex items-center pb-3'>
                        <div style={{ backgroundColor: list?.color }} className={`rounded-md w-[20px] h-[20px]`}></div>
                        <p className='px-2'>{list?.name}</p>
                    </div>
                ))}
            </div>
        </div >
    );
};

export default GaugeChart;

