import React, { useState, useEffect } from 'react'
// import rent from '../../../assests/images/rent.png'
// import user from '../../../assests/images/ap.png'
// import { IoClose } from 'react-icons/io5'
// import { Link } from 'react-router-dom'
// import GooglePlacesAutocomplete from "react-google-autocomplete";
import Home from '../../../assests/svg/home.svg'
import { Loader } from '../../../utils/loaderButton'
import { GetSavedList, PenddingSendLease, RequestRentalApp, SavedListAcept, SavedListDecline } from '../../../lib/Api/RantelServiceApi'
import moment from 'moment'
import { MylistDetail } from '../../GenericComp/MylistDetail'
import gp4 from '../../../assests/svg/Group 4188.svg'
import { useTranslation } from 'react-i18next'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import RentalsDetail from '../../../pages/Property/PropertyDetail'
import { useDispatch, useSelector } from 'react-redux'
import flash from '../../../assests/images/f5.png'
import { IoClose } from 'react-icons/io5'
import { setConfirmData, setIsLeaseCreate, setlocation } from '../../../Redux/UserAuthSlice/UserAuthSlice'
import { useLocation, useNavigate } from 'react-router-dom'
// import PasswordVerify from '../RentalRegister/PasswordVerify'
// import PdfPreview from '../../RenatalPanelComp/RentalApp/PdfPreview'
import { MdMessage } from 'react-icons/md'
import { AddRentalAppUser } from '../../../Redux/UserAuthSlice/RentalSlice'
import ViewRentalApp from './ViewRentalApp'
import { Truncate } from '../../../utils/TrucateString'
import PropertyOffCampus from '../../RenatalPanelComp/FilterRommate/PropertyDetaiOffCamp'
import PropertyOnCampus from '../../RenatalPanelComp/FilterRommate/PropertyDetailOnCamp'
import DeletePopup from '../../../utils/Popup/DeletePopup'
import { API } from '../../../config/GoolePlace'
import VerifyRentalApp from '../../RenatalPanelComp/RentalApp/VerifyRentalApp'
// import lists from '../../../lib/contentData.json'


const Saved = () => {
  const [savedList, setSaveList] = useState([])
  const [loading, setLoading] = useState(false)
  const [savedListLoader, setSavedListLoader] = useState(false)
  const [isVerify, setIsVerify] = useState(false)
  const [property, setProperty] = useState({})
  const [type, setType] = useState('')
  const [propertyDetail, setPropertyDetail] = useState({})
  const [book, setBook] = useState(false)
  const [leasePdf, setLeasePdf] = useState("")
  const [del, setDel] = useState(false)



  const location = useSelector((state) => state?.userAuth?.location)
  const { ConfirmData, isLeasedCreate } = useSelector((state) => state?.userAuth)
  const lng = useSelector((state) => state?.userAuth?.language)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const locationData = useLocation().pathname

  let check = Object?.keys(location)?.length > 0
  // let checkProperty = Object?.keys()?.length > 0

  let fetchData = async () => {
    let { resSaved } = await GetSavedList(location)
    if (resSaved) {
      setSaveList(resSaved)
      return true
    }
    else {
      return false
    }


  }

  const sendLease = async (list) => {
    let payload = {
      "list_id": list?.id
    }
    setType("signlease")
    setProperty(list)
    setSavedListLoader(true)
    let res = await PenddingSendLease(payload)
    if (res) {
      await fetchData()
      dispatch(setConfirmData({}))
      setSavedListLoader(false)
    }
  }


  const handleAcept = async (list) => {
    setProperty(list)
    setType("accept")
    setSavedListLoader(true)
    let payload = {
      "action": "accept",
      "list_id": list?.id
    }
    let { res } = await SavedListAcept(payload)
    if (res) {
      await fetchData()
      setSavedListLoader(false)
    }
    else {
      setSavedListLoader(false)
    }
  }


  const handleOpen = (item) => {
    setProperty(item)
    setDel(true)
  }


  const handleDecline = async (list) => {

    setSavedListLoader(true)
    let payload = {
      "list_id": property?.id
    }
    let { res } = await SavedListDecline(payload)
    if (res) {
      await fetchData()
      setDel(false)
      setSavedListLoader(false)
    }
    else {
      setSavedListLoader(false)
    }
  }

  const handleSendRent = async (item) => {
    setType("sendRent")
    setProperty(item)
    let payload = {
      "property_id": item?.property_id,
      "list_id": item?.id
    }

    setSavedListLoader(true)
    let { res } = await RequestRentalApp(payload)
    if (res) {
      await fetchData()
      setSavedListLoader(false)
    }

  }


  const handleDetail = (detail) => {
    setPropertyDetail(detail)
    setType("detail")
    setBook(true)
  }


  const handlePopup = (item) => {
    setType("pdf")
    setIsVerify(true)
    setLeasePdf(item)

  }
  const handleMessage = (data) => {
    dispatch(AddRentalAppUser(data))
    navigate(`/message/${data?.id}`)
  }


  const handleVerify = () => {
    setIsVerify(false)
    setBook(true)
  }

  useEffect(() => {
    let fetch = async () => {
      setLoading(true)
      // if (check) {
      const res = await fetchData()
      if (res === true) {
        setLoading(false)
      }
      else {
        setLoading(false)
      }
      // } else {
      //   setLoading(false)
      //   setSaveList([])
      // }
    }
    fetch()
  }, [lng, location])



  useEffect(() => {
    if (locationData === `/mylist` && isLeasedCreate === true) {
      sendLease(ConfirmData)
      dispatch(setIsLeaseCreate({ page: "", data: false }))
    }
  }, [location])

  const handleClosePopup = () => {
    setIsVerify(false)
    setBook(false)
    setType("")
  }


  const { t } = useTranslation()

  if (isVerify) {
    return (<DynamicPopup modalOpen={isVerify} data={<VerifyRentalApp handleClose={handleVerify} />}
      close={() => handleClosePopup()}
    />)
  }
  else {
    return (
      <>
        {book && <DynamicPopup modalOpen={book} data={
          type === "detail" ? propertyDetail?.property === null ? propertyDetail?.roommate?.property_details !== null ? <PropertyOffCampus detail={propertyDetail?.roommate} close={() => setBook(false)} /> : <PropertyOnCampus detail={propertyDetail?.roommate} close={() => setBook(false)} /> :
            <RentalsDetail detail={propertyDetail?.property} close={() => setBook(false)} />
            : type === "pdf" ? <ViewRentalApp list={leasePdf} /> : ""
        } close={() => setBook(false)} />}

        {del && <DeletePopup permition={del} loading={savedListLoader} Toggle={(val) => setDel(val)} callback={handleDecline} title={t('archive_this')} />}

        <div className=' blog_list  mt-4'>
          {
            savedList?.length > 0 ?
              <div className='grid lg:grid-cols-4 md:grid-cols-2  gap-4'>
                {
                  savedList?.map((list, i) => (
                    // <Link to="/property">
                    <div className='rounded-md bg-white shadow-md pb-4 mb-8 w-full relative' key={i}>
                      <div className='relative'>
                        <img src={list?.property !== null ? list?.property?.image : list?.roommate?.images?.length > 0 ? list?.roommate?.images[0]?.image : list?.roommate?.user?.user_image} className="w-full h-[200px] object-cover" alt="faverit_img" />
                        <div className='absolute top-0 w-full bg-black h-full bg-opacity-[0.5] rounded-t-xl'>
                          <div className='absolute top-[7%]  right-[5%]'>
                            <div className={`cursor-pointer rounded-full p-[3px] w-8 h-8 flex justify-center items-center text-white bg-[#F63030] `} onClick={() => handleOpen(list)}>
                              <div className='text-[20px] ' >
                              </div>{property?.id === list?.id && savedListLoader === true && type === "cancel" ? <div className='flex items-start' ><Loader type="single" /> </div> : <IoClose />}
                            </div>
                            <div onClick={() => handleMessage(list?.user)} className=' mt-1 cursor-pointer p-[3px] text-white bg-blue-500 w-8 h-8 flex justify-center items-center rounded-full ' >
                              <div className='text-[18px] ' >
                              </div> <MdMessage />
                            </div>
                          </div>
                          <div className='absolute p-[2px] top-[7%] text-white  left-[5%]'>
                            <div className='text-[20px] cursor-pointer bg-gray-200 rounded-full' onClick={() => handleDetail(list)}>
                              <img src={gp4} alt="detail" className='w-6 h-6' />
                            </div>
                            {list?.property && list?.property !== null && list?.property?.flashlane_expires_at !== null && list?.property?.flashlane_expires_at !== "" &&
                              <div className='pt-2'>
                                <img src={flash} alt="detail" className='w-6 h-6' />
                              </div>
                            }
                          </div>
                          <MylistDetail list={list} />
                        </div>
                      </div>
                      {check &&
                        <div className='px-3 pt-2'>
                          <p className='text-[12px]'>{list?.property !== null ? list?.property?.distance : list?.roommate?.distance}</p>
                        </div>
                      }
                      <div className='flex justify-between items-center p-3 h-[50px]'>
                        <div className='flex items-center w-[80%] '>
                          <img src={list.user?.user_image} className="w-[40px] h-[35px] border object-cover rounded-full" alt="user" />
                          <h2 className='font-bold mx-2 text-[14px]  max-w-[120px]'>{Truncate(list?.user?.first_name, 10)}</h2>
                        </div>
                        <div className='w-[50%]'>
                          <h2 className='text-[11px] font-semibold text-right'>{moment(list?.booking_details?.date_of_appointment).format('ll')}</h2>
                          <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.time_of_appointment} </h2>
                        </div>
                      </div>
                      <div className={` w-full`}>
                        {list?.roommate === null ?
                          <div className='flex justify-between flex-wrap gap-2 mx-2'>
                            {
                              list?.booking_details !== null &&
                              <button onClick={() => handleAcept(list)} className='px-6 py-1 w-full rounded-md bg-[#00AF0C] text-white'>{
                                property?.id === list?.id && savedListLoader && type === "accept" ? <div className='flex items-center justify-center' ><Loader type="single" /> </div> : t('accept')
                              }</button>
                            }

                            {
                              list?.rental_app_sent !== null ?
                                <>
                                  <button onClick={() => handlePopup(list)} className={`px-[6px] py-1  rounded-md bg-blue-500 w-full text-white`}>
                                    {t('view_rent_app')}
                                  </button>
                                </>
                                :
                                <>
                                  <button onClick={() => handleSendRent(list)}
                                    className={`px-[5px] py-1  w-full  rounded-md bg-blue-500 text-white `}>
                                    {property?.id === list?.id && type === "sendRent" && savedListLoader ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('snd_req_rnt_ap')}
                                  </button>
                                </>
                            }


                          </div>
                          :
                          <div>
                            <button onClick={() => handleAcept(list)} className='px-6 py-1 w-full rounded-md bg-[#00AF0C] text-white'>{
                              property?.id === list?.id && savedListLoader && type === "accept" ? <div className='flex items-center justify-center' ><Loader type="single" /> </div> : t('accept')
                            }</button>
                          </div>
                        }
                      </div>
                    </div>

                  ))

                }

              </div>
              :
              loading ?
                <div className='flex justify-start items-start'><Loader type="loadData" />
                </div>
                // : isLocationDenied ?
                //   <div>
                //     <p>{t('enter_loc')}</p>
                //     <GooglePlacesAutocomplete
                //       apiKey={API}
                //       options={autocompleteOptions}
                //       onPlaceSelected={async (place) => {
                //         let latitude = place?.geometry?.location?.lat()
                //         let longitude = place?.geometry?.location?.lng()
                //         setIsLocation(false)
                //         dispatch(setlocation({ latitude, longitude }))
                //       }}
                //       className='border p-2 w-full focus:outline-none rounded-md my-2'
                //       placeholder={t('location_search')}
                //     />

                //   </div>
                :
                <div className='text-center'>
                  <div className='flex justify-center items-center w-full '>
                    <div className='flex justify-center items-center'>
                      <img src={Home} alt="home" className='w-[70%]' />
                    </div>
                  </div>
                  <div className='pt-5'>
                    <h2 className='text-xl font-bold '>{t('record_not_found')}</h2>
                  </div>
                </div>
          }

        </div>
      </>
    )
  }
}

export default Saved