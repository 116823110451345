import React from 'react'
// import rent from '../../../assests/images/rent.png'
// import user from '../../../assests/images/ap.png'
// import { Link } from 'react-router-dom'
import { Loader } from '../../../utils/loaderButton'
import Home from '../../../assests/svg/home.svg'
// import moment from 'moment/moment'
import { IoClose } from 'react-icons/io5'
import gp4 from '../../../assests/svg/Group 4188.svg'
import { MylistDetail, MylistUserInfo } from '../../GenericComp/MylistDetail'
import { CancelBookList, ConfirmRequestLease, GetBookedList, PenddingSendRent } from '../../../lib/Api/RentalAppApi'
import { useState } from 'react'
import { useEffect } from 'react'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import RentalsDetail from '../../../pages/Property/PropertyDetail'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AddRentalAppUser } from '../../../Redux/UserAuthSlice/RentalSlice'
import BookAppointment from './BookAppointment'
// import { Truncate } from '../../../utils/TrucateString'
import { MdMessage } from "react-icons/md";
// import lists from '../../../lib/contentData.json'
import PdfPreview from '../RentalApp/PdfPreview'
import PropertyOffCampus from '../FilterRommate/PropertyDetaiOffCamp'
import PropertyOnCampus from '../FilterRommate/PropertyDetailOnCamp'
import VerifyRentalApp from '../RentalApp/VerifyRentalApp'

const Booked = ({ t }) => {
  const [bookList, setBookList] = useState([])
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState(false)
  const [checkLoading, setCheckLoading] = useState(false)
  const [property, setProperty] = useState({})
  const [type, setType] = useState("")
  const [isVerify, setIsVerify] = useState(false)
  const [leasePdf, setLeasePdf] = useState("")

  const location = useSelector((state) => state?.userAuth?.location)
  const lng = useSelector((state) => state?.userAuth?.language)

  let check = Object.keys(location)?.length > 0

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleMessage = (data) => {
    dispatch(AddRentalAppUser(data))
    navigate(`/message/${data?.id}`)
  }

  let fetchData = async () => {
    let { resBook } = await GetBookedList(location)
    if (resBook) {
      setBookList(resBook)
      return true
    }
  }

  const handleDetail = (list) => {
    setType("detail")
    setProperty(list)
    setDetail(true)
  }

  const handleReschdual = (list) => {
    setType("booking")
    setProperty(list)
    setDetail(true)
  }

  const handleCencel = async (item) => {
    setType("cancel")
    setProperty(item)
    let payload = {
      // "property_id": item?.property_id,
    }
    if (item?.property === null) {
      payload.roommate_id = item?.roommate?.id
    }
    else {
      payload.property_id = item?.property_id

    }
    setCheckLoading(true)
    let res = await CancelBookList(payload)
    if (res) {
      setCheckLoading(false)
      await fetchData()
    }
  }


  const handlePopup = (item, type) => {
    setType("pdf")
    setIsVerify(true)
    // if (type === "lease") {
    //   setLeasePdf(item?.property?.pdf)
    // } else {
    setLeasePdf(item?.rental_app_sent?.pdf)
    // }

  }

  const handleVerify = () => {
    setIsVerify(false)
    setDetail(true)
  }

  const handleSendRent = async (item) => {
    setType("sendRent")
    setProperty(item)
    let payload = {
      "property_id": item?.property_id,
    }
    setCheckLoading(true)
    let { res } = await PenddingSendRent(payload)
    if (res) {
      await fetchData()
      setCheckLoading(false)
    }

  }


  const handleBooking = (list) => {
    setType("book")
    // let check = list.property === null ? list?.roommate : list?.property
    setProperty(list)
    setDetail(true)

  }
  // const handleRequestLease = async (item) => {
  //   setType("sendRent")
  //   setProperty(item)
  //   let payload = {
  //     "property_id": item?.property_id,
  //   }
  //   setCheckLoading(true)
  //   let { res } = await ConfirmRequestLease(payload)
  //   if (res) {
  //     await fetchData()
  //     setCheckLoading(false)
  //   }
  // }

  const handleClosePopup = () => {
    setIsVerify(false)
    setDetail(false)
    setType("")
  }



  useEffect(() => {
    let fetch = async () => {
      setLoading(true)
      // if (check) {
      let res = await fetchData()
      if (res) {
        setLoading(false)
      }
      // } else {
      //   setBookList([])
      // }
    }
    fetch()
  }, [lng, location])



  if (isVerify) {
    return (<DynamicPopup modalOpen={isVerify} data={<VerifyRentalApp handleClose={handleVerify} />}
      close={() => handleClosePopup()}
    />)
  } else {


    return (
      <>
        {/* <Link to="/property"> */}


        {detail && <DynamicPopup modalOpen={detail} data={type === "detail" ? property?.property === null ? property?.roommate?.property_details !== null ? <PropertyOffCampus detail={property?.roommate} close={() => setDetail(false)} /> : <PropertyOnCampus detail={property?.roommate} close={() => setDetail(false)} /> : <RentalsDetail detail={property?.property} close={() => setDetail(false)} /> : type === "pdf" ? <PdfPreview pdf={leasePdf} t={t} /> : <BookAppointment property={property} />} close={() => setDetail(false)} />}
        <div className=' blog_list  mt-4'>

          {
            bookList?.length > 0 ?
              <div className='grid lg:grid-cols-4 md:grid-cols-2  gap-4'>
                {

                  bookList?.map((list, i) => (
                    <div className='rounded-md bg-white shadow-md pb-4 mb-4 w-full relative' key={i}>
                      <div className='relative'>
                        <img src={list?.property !== null ? list?.property?.image : list?.roommate?.images?.length > 0 ? list?.roommate?.images[0]?.image : list?.roommate?.user?.user_image} className="w-full h-[200px] object-cover" alt="faverit_img" />
                        <div className='absolute top-0 w-full bg-black h-full bg-opacity-[0.5]' />
                        <div className='absolute top-[7%]  right-[5%]'>
                          <div className='cursor-pointer rounded-full p-[3px] w-8 h-8 flex justify-center items-center text-white bg-[#F63030]'>
                            <div className='text-[20px] cursor-pointer' onClick={() => handleCencel(list)}> {property?.id === list?.id && checkLoading && type === "cancel" ? <div className='flex items-start' ><Loader type="single" /> </div> : <IoClose />}</div>
                          </div>
                          <div onClick={() => handleMessage(list?.user)} className='mt-1 cursor-pointer p-[3px] text-white bg-blue-500 w-8 h-8 flex justify-center items-center rounded-full' >
                            <div className='text-[18px] ' >
                            </div> <MdMessage />
                          </div>
                        </div>
                        <div className='absolute p-[2px] top-[7%] text-white bg-gray-200 rounded-full left-[5%]'>
                          <div className='text-[20px] cursor-pointer' onClick={() => handleDetail(list)}>
                            <img src={gp4} alt="detail" className='w-6 h-6' />
                          </div>
                        </div>
                        <MylistDetail list={list} />
                      </div>
                      {check &&
                        <div className='px-3 pt-2'>
                          <p className='text-[12px]'>{list?.property?.distance}</p>
                        </div>
                      }
                      <div className='flex justify-between items-center p-3 h-[50px]'>
                        <MylistUserInfo list={list} />
                        <div className='w-[50%]'>
                          <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.date_of_appointment}</h2>
                          <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.time_of_appointment} </h2>
                        </div>

                      </div>

                      <div className={` w-full`}>
                        {list?.roommate === null ?
                          <div className='flex justify-between flex-wrap gap-2 mx-2'>
                            <button onClick={() => handleReschdual(list)} className={`px-3 py-1  w-full rounded-md bg-green-500 text-white `}>
                              {t('reschedule')}
                            </button>
                            {
                              list?.rental_app_sent !== null ?
                                <>
                                  <button onClick={() => handlePopup(list)} className={`px-[6px] py-1 rounded-md bg-blue-500 w-full text-white`}>
                                    {t('view_rent_app')}
                                  </button>
                                </>
                                :
                                <>
                                  <button onClick={() => handleSendRent(list)}
                                    className={`px-[5px] py-1  w-full  rounded-md bg-blue-500 text-white `}>
                                    {property?.id === list?.id && type === "sendRent" && checkLoading ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('send_rent_ap')}
                                  </button>
                                </>
                            }

                          </div>
                          :
                          <div className='flex justify-between flex-wrap gap-2 mx-2'>
                            {
                              list?.roommate !== null && list?.booking_details === null ?
                                <>
                                  <button onClick={() => handleBooking(list)} className='px-6 py-1 rounded-md bg-[#00AF0C] w-full text-white'>{
                                    t('book')
                                  }</button>
                                </>
                                :
                                <>
                                  <button onClick={() => handleReschdual(list)} className={`px-3 w-full py-1 rounded-md bg-green-500 text-white `}>
                                    {t('reschedule')}
                                  </button>

                                </>
                            }
                          </div>
                        }
                      </div>
                    </div>

                  ))

                }

              </div>
              :
              loading ? <div className='flex justify-start items-start'><Loader type="loadData" /> </div> :
                <div className='text-center'>
                  <div className='flex justify-center items-center w-full mt-10  '>
                    {/* <div className='w-32 h-32 pb-2 bg-[#FFC000] rounded-full'>
                    <div className='flex justify-center items-center w-full '>
                      <FaHome className="text-[100px] mt-3 text-white" />
                    </div>
                  </div> */}
                    <div className='flex justify-center items-center'>
                      <img src={Home} alt="home" className='w-[70%]' />
                    </div>
                  </div>
                  <div className='pt-5'>
                    <h2 className='text-xl font-bold '>{t('record_not_found')}</h2>
                  </div>
                </div>

          }
        </div>
        {/* </Link> */}
      </>
    )
  }
}

export default Booked